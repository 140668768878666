const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const regex = new RegExp(expression);

export default function parseUrls(text: string, spaceAfter = false): string[] {
  const urls = text.match(regex) || [];
  if (spaceAfter) {
    return urls.filter(url => new RegExp(`${url}[ \r\n]`, 'g').test(text));
  }
  return urls;
}

var youtubeRegexp = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
export function getYoutubeIdFromURL (url: string): string {
  var id = url.replace(youtubeRegexp, '$1');

  if (id.includes(';')) {
    var pieces = id.split(';');

    if (pieces[1].includes('%')) {
      var uriComponent = decodeURIComponent(pieces[1]);
      id = ("http://youtube.com" + uriComponent).replace(youtubeRegexp, '$1');
    } else {
      id = pieces[0];
    }
  } else if (id.includes('#')) {
    id = id.split('#')[0];
  }

  return id
}
